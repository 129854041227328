<template>
  <div>
    <h2>Login</h2>
    <br />
    <v-form ref="form">
      <v-text-field
        v-model="username"
        :rules="[rules.required]"
        autofocus
        @keyup.enter="login"
        append-icon="mdi-account"
        class="border"
        label="Usuario"
        maxlength="200"
        outlined
      ></v-text-field>
      <v-text-field
        v-model="password"
        label="Contraseña"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        @click:append="showPassword = !showPassword"
        :rules="[rules.required]"
        @keyup.enter="login"
        class="border mt-1"
        maxlength="100"
        outlined
      ></v-text-field>

      <v-row class="justify-center" no-gutters>
        <p class="letters" @click="goToForgottenPasswordSection()">
          ¿Olvidaste tu contraseña?
        </p>
        <p class="letters" @click="goToAlternativeLoginSection()">
          Inicio de sesión alternativo
        </p>
      </v-row>

      <v-btn
        @click="login"
        class="mt-5 primary--text venusa-title-button"
        block
        medium
        color="primary"
        :loading="loading"
        :elevation="7"
        @keyup.enter="login"
        >Iniciar Sesión</v-btn
      >
    </v-form>
  </div>
</template>

<script>
import { LOGIN_SECTIONS } from "@/config/constants";
export default {
  name: "login-section",
  data() {
    return {
      username: "",
      rules: {
        required: value => !!value || "Campo Obligatorio"
      },
      password: "",
      showPassword: false,
      loading: false
    };
  },
  methods: {
    retrieveData() {
      const userData = {
        username: this.username,
        password: this.password
      };
      return userData;
    },
    async login() {
      if (this.$refs.form.validate()) {
        const user = this.retrieveData();
        this.loading = true;
        try {
          await this.$store.dispatch("users/authorize", user);
          this.loading = false;
          this.$router.push({ name: "dashboard" });
        } catch {
          this.loading = false;
        }
      }
    },
    goToForgottenPasswordSection() {
      this.$emit("changeSection", LOGIN_SECTIONS.FORGOTTEN_PASSWORD);
    },
    goToAlternativeLoginSection() {
      this.$emit("changeSection", LOGIN_SECTIONS.ALTERNATIVE_LOGIN);
    }
  }
};
</script>

<style scoped>
.venusa-title-button ::v-deep .v-btn__content {
  color: white !important;
}

.venusa-title-button ::v-deep .v-btn__loader {
  color: white !important;
}

.letters {
  color: var(--v-primary-base);
  cursor: pointer;
}
</style>
