<template>
  <v-container fluid fill-height>
    <v-row justify="center" align="center">
      <v-col cols="12" justify="center" align="center">
        <v-lazy transition="slide-y-transition">
          <login-card></login-card>
        </v-lazy>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations } from "vuex";
import LoginCard from "../../components/login/LoginCard";
export default {
  name: "login-view",
  components: {
    "login-card": LoginCard
  },
  data() {
    return {};
  },
  mounted() {
    this.toggleBackgroundDark(true);
  },
  methods: mapMutations("ux", ["toggleBackgroundDark"])
};
</script>

<style></style>
