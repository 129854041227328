<template>
  <div>
    <br />
    <h2>Recuperación de contraseña</h2>
    <template v-if="!emailSentSuccessfully">
      <br />
      <v-row class="justify-center" no-gutters>
        <p class="letters-color">
          Ingresa tu correo electrónico para recibir información sobre cómo
          cambiar tu contraseña
        </p>
      </v-row>
      <br />
      <v-form ref="form">
        <v-text-field
          v-model="email"
          :rules="[rules.required, emailRules[0], emailRules[1]]"
          autofocus
          @keyup.enter="sendEmail"
          append-icon="mdi-account"
          class="border"
          label="Correo Electrónico"
          :readonly="loading"
          maxlength="500"
          outlined
        ></v-text-field>

        <v-row class="justify-center" no-gutters>
          <p class="letters-color letters-cursor" @click="goToLoginSection()">
            Ir a Inicio de sesión
          </p>
        </v-row>

        <v-btn
          @click="sendEmail"
          class="mt-5 primary--text venusa-title-button"
          block
          medium
          color="primary"
          :loading="loading"
          :elevation="7"
          @keyup.enter="sendEmail"
          >Enviar Correo</v-btn
        >
      </v-form>
      <br />
    </template>
    <v-scroll-x-transition mode="out-in">
      <div v-if="emailSentSuccessfully">
        <v-icon color="primary" class="image-success" size="100"
          >mdi-checkbox-marked-circle-outline</v-icon
        >
        <br />
        <v-row class="justify-center" no-gutters>
          <h2 class="letters-color">¡Envio exitoso!</h2>
        </v-row>
        <br />
        <v-row class="justify-center" no-gutters>
          <span class="letters-color"
            >Accede a tu cuenta de correo electrónico:</span
          >
          <h4 class="letters-color">{{ email }}</h4>
          <span class="letters-color"
            >y sigue las instrucciones para cambiar tu contraseña</span
          >
        </v-row>
        <br />
        <v-row class="justify-center" no-gutters>
          <span class="letters-color letters-cursor" @click="goToLoginSection()"
            >Ir a Inicio de sesión</span
          >
        </v-row>
      </div>
    </v-scroll-x-transition>
  </div>
</template>

<script>
import { LOGIN_SECTIONS } from "@/config/constants";
import Repository from "@/services/repositories/repositoryFactory";
const UserRepository = Repository.get("users");

export default {
  name: "forgotten-password-section",
  data() {
    return {
      email: "",
      emailSentSuccessfully: false,
      rules: {
        required: value => !!value || "Campo Obligatorio"
      },
      emailRules: [
        value => /.+@.+\..+/.test(value) || "Formato de correo no válido",
        value =>
          /^\S+$/.test(value) ||
          "El correo no puede contener espacios en blanco"
      ],
      loading: false
    };
  },
  methods: {
    async sendEmail() {
      if (this.$refs.form.validate()) {
        const email = {
          email: this.email
        };
        this.loading = true;
        await UserRepository.sendForgottenPasswordEmail(email)
          .then(() => {
            this.emailSentSuccessfully = true;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    goToLoginSection() {
      this.$emit("changeSection", LOGIN_SECTIONS.LOGIN);
    }
  }
};
</script>

<style scoped>
.venusa-title-button ::v-deep .v-btn__content {
  color: white !important;
}

.venusa-title-button ::v-deep .v-btn__loader {
  color: white !important;
}

.letters-color {
  color: var(--v-primary-base);
}

.letters-cursor {
  cursor: pointer;
}

.image-success {
  -webkit-filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
  filter: drop-shadow(5px 5px 5px rgba(0, 0, 0, 0.3));
}
</style>
