<template>
  <div>
    <v-card max-width="800" max-height="800" class="mx-4" :elevation="24">
      <v-container class="card">
        <v-row justify="end" class="d-flex justify-end">
          <v-spacer />
          <v-col
            cols="12"
            sm="6"
            align="start"
            justify="end"
            class="d-flex align-end justify-start venusa-title"
          >
            <div>
              <h1
                class="mb-n3"
                :class="$vuetify.breakpoint.mdAndUp ? 'venusa-font' : ''"
              >
                VENUSA
              </h1>
              <h1 :class="$vuetify.breakpoint.mdAndUp ? 'venusa-font' : ''">
                LIVE
              </h1>
            </div>
          </v-col>
          <v-col cols="12" sm="6" class="pa-8 height-allowed">
            <v-scroll-x-transition mode="out-in" class="position2">
              <login-section
                v-if="currentLoginSection === loginSections.LOGIN"
                :class="
                  currentLoginSection === loginSections.LOGIN ? '' : 'position'
                "
                @changeSection="changeSection"
              ></login-section>
              <forgotten-password-section
                v-if="currentLoginSection === loginSections.FORGOTTEN_PASSWORD"
                :class="
                  currentLoginSection === loginSections.FORGOTTEN_PASSWORD
                    ? ''
                    : 'position'
                "
                @changeSection="changeSection"
              ></forgotten-password-section>
              <alternative-login-section
                v-if="currentLoginSection === loginSections.ALTERNATIVE_LOGIN"
                :class="
                  currentLoginSection === loginSections.ALTERNATIVE_LOGIN
                    ? ''
                    : 'position'
                "
                @changeSection="changeSection"
              ></alternative-login-section>
            </v-scroll-x-transition>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { LOGIN_SECTIONS } from "@/config/constants";
import LoginSection from "./LoginSection";
import ForgottenPasswordSection from "./ForgottenPasswordSection";
import AlternativeLoginSection from "./AlternativeLoginSection";
export default {
  name: "login-card",
  components: {
    "login-section": LoginSection,
    "forgotten-password-section": ForgottenPasswordSection,
    "alternative-login-section": AlternativeLoginSection
  },
  data() {
    return {
      loginSections: LOGIN_SECTIONS,
      currentLoginSection: LOGIN_SECTIONS.LOGIN
    };
  },
  methods: {
    changeSection(section) {
      this.currentLoginSection = section;
    }
  }
};
</script>

<style scoped>
.venusa-title {
  color: white !important;
  background: rgb(232, 84, 231);
  background: linear-gradient(
    90deg,
    rgba(232, 84, 231, 1) 8%,
    rgba(205, 140, 236, 1) 100%
  );
}

.venusa-font {
  font-size: 60px;
}

.card {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.position {
  position: absolute !important;
}
.position2 {
  position: relative !important;
}
.height-allowed {
  min-height: 442px !important;
}
</style>
